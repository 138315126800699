import {Box} from '@material-ui/core'
import StateCitySelect from 'app/components/form/StateCitySelect'
import {companiesService} from 'app/services'
import {validarCNPJ} from 'lib/validateFields'
import {useSnackbar} from 'notistack'
import React, {useCallback, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import InputMask from 'react-input-mask'
import IntlCurrencyInput from 'react-intl-currency-input'

import {Container, Title} from './styles'

const ProposalDetails = ({formik, onNextStep, onPreviousStep}) => {
  const [loading, setLoading] = useState(false)
  const {enqueueSnackbar} = useSnackbar()

  const {
    handleChange,
    setFieldValue,
    values: {areaAlias, company_document, link_with_city, city, share_capital, business_name, main_activity},
  } = formik

  const searchCompanyDocument = useCallback(async () => {
    if (!validarCNPJ(company_document)) {
      enqueueSnackbar('Por favor, verifique se o número digitado é um CNPJ válido', {
        variant: 'error',
      })
      return
    }

    try {
      setLoading(true)

      const response = await companiesService.getCompany({document: company_document})

      setFieldValue('business_name', response.name)
      setFieldValue('share_capital', response.share_capital)
      setFieldValue('company', response)
      setFieldValue('main_activity', response.main_activity.text)
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao buscar o documento da empresa', {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [company_document, enqueueSnackbar, setFieldValue])

  const onChange = (e) => {
    const {checked} = e.target

    setFieldValue('link_with_city', checked)
  }

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
        <Box marginBottom="40px">
          <Title>Detalhes da oportunidade PRIVADA</Title>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom="50px">
          <Box>
            <Form.Label style={{fontSize: '16px'}}>
              <b>Nome*</b>
            </Form.Label>
            <small style={{fontSize: 12, color: '#868E96', fontFamily: 'Nunito', display: 'block', marginBottom: 5}}>
              Como esta oportunidade deve se chamar?
            </small>
            <Form.Control
              autoComplete="off"
              placeholder="Ex.: Fábrica de Papel Celulose"
              name="areaAlias"
              onChange={handleChange}
              value={areaAlias}
              style={{width: '481px'}}
              required
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="20px">
          <Box display="flex" flex={1} flexDirection="row" marginBottom="20px">
            <Box display="flex" flex={1}>
              <Form.Check checked={link_with_city} onChange={onChange} type="checkbox" id="form-vincular-cidade" />
              <label
                htmlFor="form-vincular-cidade"
                style={{fontWeight: 'bold', fontSize: 16, color: '#1D1D1D', fontFamily: 'Nunito'}}>
                Vincular oportunidade a um município
              </label>
            </Box>
          </Box>
          {link_with_city && (
            <Box marginBottom="60px">
              {' '}
              <StateCitySelect changeCity={(cityId) => setFieldValue('city', cityId)}></StateCitySelect>
            </Box>
          )}
        </Box>
        <Box marginBottom="40px">
          <Title>Dados da empresa</Title>
        </Box>
        <Box alignItems="flex-end" display="flex" flexDirection="row" marginBottom="20px">
          <Box display="flex" flex={1} flexDirection="row" marginBottom="10px" marginRight="38px">
            <Box alignItems="flex-end" display="flex" flex={1} flexDirection="row">
              <Box display="flex" flexGrow={2} flex={1} flexDirection="column" marginRight="12px">
                <Form.Label>
                  <b style={{fontWeight: 'bold'}}>CNPJ*</b>
                </Form.Label>
                <InputMask
                  className="form-control"
                  name="company_document"
                  onChange={handleChange}
                  onBlur={searchCompanyDocument}
                  value={company_document}
                  placeholder="Ex.: 38.578.388/0001-85"
                  mask="99.999.999/9999-99"
                  disabled={loading}
                  required
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Button onClick={searchCompanyDocument} variant="outline-primary" disabled={loading}>
                  {loading ? 'Aguarde ...' : 'Buscar Dados'}
                </Button>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flex={1} flexDirection="row" marginBottom="10px">
            <Box alignItems="flex-end" display="flex" flex={1} flexDirection="row">
              <Box display="flex" flexGrow={2} flex={1} flexDirection="column">
                <Form.Label>
                  <b style={{fontWeight: 'bold'}}>Capital social</b>
                </Form.Label>
                <IntlCurrencyInput
                  autoComplete="off"
                  placeholder="Ex.: R$ 800.000,00"
                  name="share_capital"
                  className="form-control"
                  style={{width: '100%'}}
                  disabled={loading}
                  currency="BRL"
                  config={{
                    locale: 'pt-BR',
                    formats: {
                      number: {
                        BRL: {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      },
                    },
                  }}
                  onChange={handleChange}
                  value={share_capital}
                  readOnly
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="row" marginBottom="10px" marginRight="38px" width="100%">
            <Box alignItems="flex-end" display="flex" flex={1} flexDirection="row">
              <Box display="flex" flexGrow={2} flex={1} flexDirection="column">
                <Form.Label>
                  <b style={{fontWeight: 'bold'}}>Razão social</b>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  placeholder="Ex.: Citymatch Sistemas Digitais Spe LTDA."
                  name="business_name"
                  onChange={handleChange}
                  value={business_name}
                  disabled={loading}
                  readOnly
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" marginBottom="10px" width="100%">
            <Box alignItems="flex-end" display="flex" flex={1} flexDirection="row">
              <Box display="flex" flexGrow={2} flex={1} flexDirection="column">
                <Form.Label>
                  <b style={{fontWeight: 'bold'}}>Atividade econômica (CNAE Primário)</b>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  placeholder="Ex.: Venda de Soja"
                  name="main_activity"
                  onChange={handleChange}
                  value={main_activity}
                  disabled={loading}
                  readOnly
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>

        <Button
          onClick={onNextStep}
          disabled={!areaAlias || !company_document || !business_name || !main_activity || (link_with_city && !city)}>
          Próximo passo ⇀
        </Button>
      </Box>
    </Container>
  )
}

export default ProposalDetails
